"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseBrandingStore = void 0;
const vue_demi_1 = require("vue-demi");
const colorFunctions_1 = require("../../helpers/colorFunctions");
exports.anamneseBrandingStore = (0, vue_demi_1.reactive)({
    state: {
        brandColor: '#123456',
        brandColor2: '#123456',
        get brandColorContrastText() {
            let brightness = (0, colorFunctions_1.colorBrightness)(this.brandColor);
            let blackText = brightness > 160;
            return blackText ? '#000' : '#fff';
        },
        // use :style="cssVariables" binding to apply css variables
        get cssVariables() {
            return {
                '--brand-color': this.brandColor,
                '--brand-color2': this.brandColor2,
                '--brand-color-contrast-text': this.brandColorContrastText,
            };
        },
    },
    setBrandColor(brandColor) {
        this.state.brandColor = brandColor;
    },
    setBrandColor2(brandColor2) {
        this.state.brandColor2 = brandColor2;
    },
});
