"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var anamneseContextStore_1 = require("@rose/common-ui/src/anamnese/state/anamneseContextStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    translationStatusQuestionnaire: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {
      languages: anamneseContextStore_1.anamneseContextStore.state.languages,
      anamneseContextStore: anamneseContextStore_1.anamneseContextStore
    };
  },
  computed: {
    defaultLanguageKey: function defaultLanguageKey() {
      return anamneseContextStore_1.anamneseContextStore.state.defaultLanguageKey;
    },
    activeLanguageKey: {
      get: function get() {
        return anamneseContextStore_1.anamneseContextStore.state.activeLanguageKey;
      },
      set: function set(value) {
        anamneseContextStore_1.anamneseContextStore.setActiveLanguageKey(value);
      }
    }
  }
});