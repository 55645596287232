"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseContextStore = void 0;
const vue_demi_1 = require("vue-demi");
const types_1 = require("../../../../types");
exports.anamneseContextStore = (0, vue_demi_1.reactive)({
    state: {
        interpolationMap: undefined,
        predefinedSignaures: undefined,
        languages: [
            { text: 'Deutsch', value: 'de', flag: '🇩🇪' },
            { text: 'English', value: 'en', flag: '🇬🇧' },
            { text: 'Türkçe', value: 'tr', flag: '🇹🇷' },
            { text: 'العربية', value: 'ar', flag: '🇸🇦' },
            { text: 'русский', value: 'ru', flag: '🇷🇺' },
            { text: 'italiano', value: 'it', flag: '🇮🇹' },
            { text: 'polski', value: 'pl', flag: '🇵🇱' },
            { text: 'français', value: 'fr', flag: '🇫🇷' },
        ],
        activeLanguageKey: 'de',
        defaultLanguageKey: 'de',
        get activeLanguage() {
            return this.languages.find(l => l.value === this.activeLanguageKey);
        },
        get defaultLanguage() {
            return this.languages.find(l => l.value === this.defaultLanguageKey);
        },
        get defaultLanguageActive() {
            return this.activeLanguageKey === this.defaultLanguageKey;
        },
    },
    setInterpolationMap(interpolationMap) {
        this.state.interpolationMap = interpolationMap;
    },
    setPredefinedSignatures(predefinedSignaures) {
        this.state.predefinedSignaures = predefinedSignaures;
    },
    setActiveLanguageKey(activeLanguageKey) {
        this.state.activeLanguageKey = activeLanguageKey;
    },
    getTranslationProgressForQuestionnaire: (questionnaire, targetLang) => {
        targetLang = targetLang !== null && targetLang !== void 0 ? targetLang : exports.anamneseContextStore.state.activeLanguageKey;
        let propertiesToTranslate = exports.anamneseContextStore.getTranslatablePropertiesForQuestionnaire(questionnaire);
        let translatedProperties = propertiesToTranslate.filter(prop => exports.anamneseContextStore.translationExists(prop.object, prop.property, targetLang));
        return {
            total: propertiesToTranslate.length,
            translated: translatedProperties.length,
            percentage: Math.floor((translatedProperties.length / propertiesToTranslate.length) * 100),
        };
    },
    getTranslatablePropertiesForElement: (elm) => {
        var _a, _b;
        let propertiesToTranslate = [];
        if (elm.type === types_1.QuestionnaireElementType.QUESTION) {
            propertiesToTranslate.push({ object: elm.question, property: 'questionLong' });
            let valueTypeConfig = elm.question.valueTypeConfig;
            if (valueTypeConfig) {
                propertiesToTranslate.push({ object: valueTypeConfig, property: 'commentPlaceholder' });
                if ('allowCustomOption' in valueTypeConfig && valueTypeConfig.allowCustomOption) {
                    propertiesToTranslate.push({ object: valueTypeConfig, property: 'customOptionName' });
                    propertiesToTranslate.push({ object: valueTypeConfig, property: 'customOptionPlaceholder' });
                }
                propertiesToTranslate.push({ object: valueTypeConfig, property: 'placeholder' });
                propertiesToTranslate.push({ object: valueTypeConfig, property: 'minText' });
                propertiesToTranslate.push({ object: valueTypeConfig, property: 'maxText' });
                (_a = valueTypeConfig.availableItems) === null || _a === void 0 ? void 0 : _a.forEach((item, index) => {
                    propertiesToTranslate.push({ object: valueTypeConfig.availableItems[index], property: 'name' });
                });
                (_b = valueTypeConfig.selectOptions) === null || _b === void 0 ? void 0 : _b.forEach((item, index) => {
                    propertiesToTranslate.push({ object: valueTypeConfig.selectOptions[index], property: 'name' });
                });
            }
        }
        if (elm.type === types_1.QuestionnaireElementType.RICH_TEXT) {
            propertiesToTranslate.push({ object: elm, property: 'richText' });
        }
        return propertiesToTranslate;
    },
    isElementFullyTranslated: (elm) => {
        let props = exports.anamneseContextStore.getTranslatablePropertiesForElement(elm);
        props = props.filter(({ object, property }) => {
            let value = object[property];
            return value && typeof value === 'string';
        });
        return props.every(prop => exports.anamneseContextStore.translationExists(prop.object, prop.property));
    },
    getTranslatablePropertiesForQuestionnaire: (questionnaire) => {
        let propertiesToTranslate = [];
        [...questionnaire.questionnairePages, questionnaire.signaturePage].forEach(page => {
            propertiesToTranslate.push({ object: page, property: 'title' });
            page.pageElements.forEach(elm => {
                propertiesToTranslate.push(...exports.anamneseContextStore.getTranslatablePropertiesForElement(elm));
            });
        });
        propertiesToTranslate = propertiesToTranslate.filter(({ object, property }) => {
            let value = object[property];
            return value && typeof value === 'string';
        });
        return propertiesToTranslate;
    },
    getTranslatedString: (obj, key, langkey) => {
        return (0, types_1.getTranslatedStringRaw)(obj, key, langkey !== null && langkey !== void 0 ? langkey : exports.anamneseContextStore.state.activeLanguageKey);
    },
    translationExists: (obj, key, langkey) => {
        var _a;
        langkey = langkey !== null && langkey !== void 0 ? langkey : exports.anamneseContextStore.state.activeLanguageKey;
        if (exports.anamneseContextStore.state.defaultLanguageKey === langkey) {
            return true;
        }
        let translationObjectKey = key + '_t';
        return !!((_a = obj[translationObjectKey]) === null || _a === void 0 ? void 0 : _a[langkey]);
    },
    setTranslatedString: (obj, key, val, langkey) => {
        let activeLanguageKey = langkey !== null && langkey !== void 0 ? langkey : exports.anamneseContextStore.state.activeLanguageKey;
        if (activeLanguageKey === exports.anamneseContextStore.state.defaultLanguageKey) {
            (0, vue_demi_1.set)(obj, key, val);
            return;
        }
        let translationObjectKey = key + '_t';
        let t = obj[translationObjectKey];
        if (!t) {
            t = {};
            (0, vue_demi_1.set)(obj, translationObjectKey, t);
        }
        (0, vue_demi_1.set)(t, activeLanguageKey, val);
    },
});
